/* eslint-disable react/display-name */
import React, { useState, useCallback } from 'react';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  styled,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';

// Definicja typów dla styled components
const StyledButton = styled(Button)<{
  component?: React.ElementType;
}>(({ theme }) => ({
  padding: theme.spacing(1, 2),
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '1rem',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    transform: 'translateY(-2px)',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9rem',
    padding: theme.spacing(0.75, 1.5),
  },
}));

const StyledMenuItem = styled(MenuItem)<{
  component?: React.ElementType;
}>(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  minWidth: 200,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}));

const navigationItems = [
  { label: 'O nas', path: '/', description: 'Poznaj naszą firmę i wartości' },
  { label: 'Konsulting | Doradztwo', path: '/konsulting-doradztwo', description: 'Profesjonalne usługi doradcze' },
  { label: 'Rejestracja spółek', path: '/rejestracja-spolek', description: 'Kompleksowa pomoc w rejestracji spółek' },
  { label: 'Księgowość', path: '/ksiegowosc', description: 'Usługi księgowe dla firm' },
  { label: 'Finansowanie', path: '/finansowanie', description: 'Rozwiązania finansowe dla biznesu' },
];

const Navbar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box
      component="nav"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
      }}
      role="navigation"
      aria-label="Menu główne"
    >
      {isMobile ? (
        <>
          <IconButton
            size="large"
            edge="end"
            color="primary"
            aria-label="menu"
            onClick={handleMenu}
            aria-controls="menu-mobile"
            aria-haspopup="true"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-mobile"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {navigationItems.map((item) => (
              <RouterLink
                key={item.path}
                to={item.path}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <StyledMenuItem
                  onClick={handleClose}
                  aria-label={item.description}
                >
                  {item.label}
                </StyledMenuItem>
              </RouterLink>
            ))}
          </Menu>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            gap: { xs: 1, sm: 2, md: 3 },
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}
        >
          {navigationItems.map((item) => (
            <RouterLink
              key={item.path}
              to={item.path}
              style={{ textDecoration: 'none' }}
            >
              <StyledButton
                color="primary"
                aria-label={item.description}
              >
                {item.label}
              </StyledButton>
            </RouterLink>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Navbar;
