import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'primary.main',
        color: 'secondary.main',
        py: 6,
        mt: 'auto',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              White Collars
            </Typography>
            <Typography variant="body2">
              Zespół interdyscyplinarnych specjalistów pomagający przedsiębiorcom w osiągnięciu celów.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Kontakt
            </Typography>
            <Typography variant="body2">
              Email: kontakt@whitecollars.com.pl
              <br />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Usługi
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Link href="/rejestracja-spolek" color="secondary" sx={{ mb: 1 }}>
                Rejestracja spółek
              </Link>
              <Link href="/ksiegowosc" color="secondary" sx={{ mb: 1 }}>
                Księgowość
              </Link>
              <Link href="/finansowanie" color="secondary">
                Finansowanie
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          align="center"
          sx={{ mt: 4, borderTop: '1px solid rgba(255,255,255,0.1)', pt: 2 }}
        >
          © {new Date().getFullYear()} White Collars. Wszystkie prawa zastrzeżone.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
